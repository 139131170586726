.parent {
    font-family: 'Helvetica Neue';
    color: black;
}

.header {
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    margin: 0px;
}

.description {
    margin: 0;
    margin-bottom: 20px;
}

.content {
    max-width: 950px;
    margin: auto;
    margin-bottom: 50px;
    padding: 0 10px;
}

.quoteContainer {
    display: flex;
    flex-wrap: wrap;
}

.authorContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
}

.word {
    display: flex;
}

.results {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: fit-content;
}

