.description {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 20px;
  color: #2d2b28;
}

.imageGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.imageBox {
  width: fit-content;
  width: calc(50% - 40px);
  margin-right: 40px;
}

.imageBoxCaption {
  margin-bottom: 30px;
}

.imageBoxNoCaption {
  margin-bottom: 50px;
}

.image {
  aspect-ratio: 1.8;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #0e1928;
}

.caption {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: var(--color);
  margin-top: 25px;
  margin-right: 30px;
}

@media (max-width: 800px) {
  .image {
    width: 100%;
  }

  .imageBox {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 700px) {
  .description {
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  .imageBoxCaption {
    margin-bottom: 25px;
  }

  .imageBoxNoCaption {
    margin-bottom: 40px;
  }
}
