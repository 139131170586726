/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700,800,700italic,regular,bold,bolder,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

body {
  font-family: "Work Sans";
  font-size: 15px;
  line-height: 1.5;
  --color: #1e413d;
  --tan: #ebe2d8;
  --brown: #2d2b28;
  color: black;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
