.toolbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px 10px 40px;
    border-bottom: 1px solid #000000;
    margin-bottom: 40px;
}

.icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.header {
    font-weight: 700;   
    font-size: 26px;
}