.parent {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: auto
}

.headerBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 40px;
}

.header {
    font-size: 30px;
    font-weight: bold;
    flex: 1;
    text-align: center;
    margin-left: 30px;
}

.closeIcon {
    cursor: pointer;
}

.setting {
    display:  flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.settingName {
    font-size: 25px;
    font-weight: 500;
}

.settingText {
    color: #6D6D6D;
    font-size: 18px;
    font-weight: normal;
}

.toggleBox {
    border-radius: 50px;
    width: 75px;
    height: 38px;
    background: #C4C4C4;
    cursor: pointer;
}


.toggleBoxOn {
    background: #6792AA;
}

.toggleCircle {
    background-color: white;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    margin-top: 4px;
    margin-left: 5px;
}

.toggleCircleOn {
    margin-left: 40px;
}

.text {
    font-size: 18px;
    font-weight: 400;
    margin: 15px 0;
}

.line {
    width: 100%;
    height: 2px;
    background: #A2A2A2;
    border-radius: 50px;
    margin: 50px 0;
}