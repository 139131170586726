.box {
  display: flex;
  background: #ffffff;
  width: 100%;
  margin: 20px 0 50px 0;
  border: 2px solid #0e1928;
  border-radius: 5px;
}

.webImg {
  object-fit: cover;
  border-radius: 3px 0px 0px 3px;
  width: 40%;
  box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.3);
}

.mobileImg {
  border-radius: 8px;
  box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.3);
  margin: 10px 0;
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 40px;
}

.subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
}

.title {
  font-weight: bold;
  font-size: 38px;
  line-height: 40px;
}

.description {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 15px;
  margin-top: 12px;
}

.visitBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}

.visitText {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 15px;
}

@media (max-width: 700px) {
  .box {
    box-shadow: 0px 0px 38px rgb(0 0 0 / 22%), -18px 18px 0px -3px #0e1928;
  }

  .webImg {
    display: none;
    width: 0%;
  }

  .mobileImg {
    display: unset;
  }

  .content {
    width: 100%;
    padding: 30px;
  }
}
