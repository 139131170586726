.page {
  max-width: 1100px;
  padding: 58px 80px;
  margin: auto;
}

.flexBox {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.noUnderline:hover {
  text-decoration: none;
}

/* ******  Title page **********  */

.topBar {
  background-color: var(--color);
  width: 100%;
  height: 40px;
}

.titlePage {
  background-color: var(--tan);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  padding: 0 30px;
}

.titleTextBox {
  max-width: 60%;
}

.hiText {
  color: #1e413d;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
}

.nameText {
  font-family: "Lora", serif;
  color: var(--brown);
  font-size: 100px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.titleImg {
  margin-top: 20px;
  margin-right: 50px;
  max-height: 95%;
  align-self: flex-end;
}

@media (max-width: 1150px) {
  .hiText {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .nameText {
    font-size: 80px;
  }
}

@media (max-width: 800px) {
  .titlePage {
    padding: 0;
    padding-right: 10px;
  }
  .titleImg {
    margin-top: 10px;
    margin-right: 20px;
    margin-left: -80px;
  }
  .hiText {
    font-size: 20px;
  }
  .nameText {
    font-size: 55px;
  }
  .line {
    max-width: 300px;
  }
}

@media (max-width: 600px) {
  .titleImg {
    margin-left: -120px;
    margin-right: 5px;
  }
  .hiText {
    font-size: 15px;
  }
  .nameText {
    font-size: 45px;
  }
  .line {
    max-width: 200px;
  }
}

@media (max-width: 450px) {
  .titleImg {
    margin-left: -20px;
    max-height: 300px;
    align-self: center;
  }
  .hiText {
    font-size: 12px;
  }
  .nameText {
    font-size: 38px;
  }
  .line {
    max-width: 150px;
  }
}

/* ******  I AM page ********  */

.bioPage {
  background: var(--color);
  padding: 80px 150px;
}

.bioText {
  color: #f1eeeb;
  font-size: 22px;
  font-weight: 400;
  line-height: 200%;
}

@media (max-width: 800px) {
  .bioPage {
    padding: 80px 50px;
  }
  .bioText {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .bioPage {
    padding: 60px 50px;
  }
  .bioText {
    font-size: 16px;
    line-height: 200%;
  }
}

/* ******  Projects page ********  */

.tanBg {
  background-color: var(--tan);
}

.projects {
  padding: 58px 80px 25px 80px;
  margin: auto;
  max-width: 1100px;
}

.projectsTitle {
  text-transform: uppercase;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 50px;
  color: var(--brown);
}

.pressTitle {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
}

.pressBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pressItem {
  border: 0.5px solid #0e1928;
  box-shadow: 0px 2px 20px -3px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  height: 34px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 13px;
  margin-bottom: 15px;
}

.testimonial {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
  color: #2e2e2e;
  margin-bottom: 8px;
}

@media (max-width: 700px) {
  .projects {
    padding: 50px 30px 25px 30px;
  }

  .projectsTitle {
    font-size: 60px;
    line-height: 50px;
  }
}

/* ******  Ted page ********  */

.ted {
  background-color: var(--color);
  color: white;
  padding-bottom: 110px;
}

.tedTitles {
  max-width: 1100px;
  margin: auto;
  padding: 70px 80px 65px 80px;
}

.tedSubtitle {
  font-weight: 400;
  font-size: 23px;
  margin-bottom: 3px;
  font-family: "Work Sans";
  font-weight: 400;
}

.tedTitle {
  text-transform: uppercase;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 50px;
}

.tedRed {
  background: #58000d;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 12px 0px;
  width: calc(100% - 105px);
  padding: 15px 83px 55px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  font-weight: 400;
  position: relative;
  z-index: initial;
}

.video {
  border: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  z-index: 1;
}

.tedContent1 {
  font-size: 24px;
  margin-top: 30px;
  z-index: 1;
}

.tedContent2 {
  font-size: 26px;
  z-index: 1;
}

.tedOverlay {
  background: #811922;
  border-radius: 0px 0px 12px 0px;
  width: calc(100% - 20px);
  height: 100%;
  position: absolute;
  top: -20px;
  left: 0px;
  z-index: 0;
}

@media (max-width: 700px) {
  .tedTitles {
    padding: 70px 30px 55px 30px;
  }

  .tedSubtitle {
    font-size: 18px;
    margin-bottom: 3px;
  }

  .tedTitle {
    font-size: 40px;
  }

  .tedRed {
    width: calc(100% - 20px);
    padding: 25px 50px 45px 30px;
    text-align: left;
  }

  .video {
    width: 100%;
    height: 220px;
  }

  .tedContent1 {
    font-size: 16px;
  }

  .tedContent2 {
    font-size: 23px;
  }
}

/* ******  Adventure page ********  */

.adventure {
  background-color: var(--tan);
}

.adventurePage {
  max-width: 1300px;
  margin: auto;
}

.adventureHeaderBox {
  margin-top: 20px;
  color: var(--brown);
}

.adventureDescription {
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 20px;
  color: #2d2b28;
}

@media (max-width: 700px) {
  .adventurePage {
    padding: 58px 30px;
  }
}

/* ******  Footer ********  */

.greenBg {
  background-color: var(--color);
}

.footer {
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 90px 70px;
}

.touchText {
  text-transform: uppercase;
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 30px;
  color: #ece8e8;
  text-align: left;
}

.contactButton {
  background: #ffffff;
  box-shadow: 0px 4px 72px rgba(47, 177, 175, 0.14);
  border-radius: 37px;
  font-weight: 700;
  font-size: 18px;
  border: none;
  height: 44px;
  width: 180px;
  margin-top: 26px;
}
