.letterContainer {
    min-width: 30px;
    text-align: center;
    text-transform: uppercase;
    margin: 10px 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.input {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    border: 2px solid #D3D6DA;
    width: 38px;
    height: 38px;
}

.inputFilled {
    border: 2px solid #878A8C;
}
.highlighted {
    background-color: rgb(220, 245, 245);
    border-radius: 4px;
}

.warning {
    background-color: rgb(240, 200, 200);
    border-radius: 4px;
}

.letter {
    margin: 0;
    margin-top: 5px;
    color: #717579;
    font-weight: 500;
}